import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Regular, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import Image from '~shared/Image'
import Link from '~shared/Link'

const IconWrapper = styled.div`
    svg {
        width: 32px;
        height: 32px;
    }

    @media ${MqTablet} {
        svg {
            width: 48px;
            height: 48px;
        }
    }
`

/* eslint-disable max-len */
const Icon = () => (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M24 0c17.736 0 24 6.293 24 24 0 17.64-6.368 24-24 24C6.42 48 0 41.558 0 24 0 6.375 6.316 0 24 0z"
            fill="#F3F3F3"
        />
        <g
            clipPath="url(#clip0)"
            stroke="#0C009A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M24 34.8V17.387s-2.455-2.727-10.732-2.837a.516.516 0 00-.52.52v16.374a.516.516 0 00.5.52C21.543 32.07 24 34.8 24 34.8zM21 23.855a21.255 21.255 0 00-5.26-1.055M21 28.05A21.313 21.313 0 0015.74 27M27 23.855a21.256 21.256 0 015.26-1.055M27 28.05c1.702-.57 3.47-.923 5.26-1.05" />
            <path d="M32.26 14.67c.76-.066 1.582-.108 2.472-.12a.515.515 0 01.518.52v16.374a.516.516 0 01-.5.52C26.457 32.07 24 34.8 24 34.8M24 34.8V17.387s1.143-1.27 4.5-2.111" />
            <path d="M32.25 19.8v-6.6c-1.264.101-2.518.302-3.75.6v6l1.875-1.5 1.875 1.5z" />
        </g>
        <defs>
            <clipPath id="clip0">
                <path fill="#fff" transform="translate(12 12)" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

const Avatar = () => (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path
            d="M0 4a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v40a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Z"
            fill="#F8F8F8"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.75 12.75c-1.54 0-2.92.688-3.848 1.773a9.143 9.143 0 0 0 5.91 2.165c.938 0 1.868-.143 2.76-.424a5.065 5.065 0 0 0-4.822-3.514Zm-7.313 5.063a7.278 7.278 0 0 1 1.029-3.742 7.312 7.312 0 1 1-1.028 3.741Zm4.918.218a11.399 11.399 0 0 1-2.511-1.471 5.063 5.063 0 1 0 9.915 1.992 11.396 11.396 0 0 1-7.404-.521Zm3.257 7.515a8.306 8.306 0 0 0-.96-.029c-.298-.008-.602 0-.914.029a11.254 11.254 0 0 0-10.238 11.2 1.125 1.125 0 1 0 2.25.002 9.004 9.004 0 0 1 8.925-8.998 9.004 9.004 0 0 1 8.925 8.998 1.125 1.125 0 1 0 2.25-.001 11.256 11.256 0 0 0-10.238-11.201Z"
            fill="#0C009A"
        />
    </svg>
)
/* eslint-enable max-len */

const Headshot = styled.div`
    background: #f8f8f8;
    border-radius: 4px;
    overflow: hidden;
    width: 48px;
    height: 48px;

    img {
        width: 100%;
    }
`

const HeadshotWrapper = styled.div``

const Title = styled.h3`
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: ${Regular};
    margin: 0;

    @media ${MqTablet} {
        font-size: 1.125rem;
        line-height: 2rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    @media ${MqDesktop} {
        font-size: 1.5rem;
    }
`

const Text = styled.p`
    margin: 0;
    color: #adadad;
    font-size: 0.875rem;
    line-height: 1.25rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

const Author = styled.p`
    margin: 0;
    color: #adadad;
    font-size: 0.75rem;
    line-height: 1.25rem;
    text-transform: uppercase;
`

const Content = styled.div`
    overflow: hidden;

    ${Text} {
        display: none;
    }

    @media ${MqTablet} {
        ${Text} {
            display: block;
            margin-top: 0.325rem;
        }
    }
`

const UnstyledPost = forwardRef(function UnstyledPost(
    { className, title, children, author, url, userImageUrl, style },
    ref,
) {
    return (
        <Link href={url} className={className} newTab ref={ref} style={style} noDecoration noHover>
            <IconWrapper>
                <Icon />
            </IconWrapper>
            <Content>
                <Title>{title}</Title>
                <Text>{children}</Text>
            </Content>
            <HeadshotWrapper>
                <Headshot>
                    {userImageUrl && (
                        <Image
                            alt={author}
                            height="48"
                            loading="lazy"
                            src={userImageUrl}
                            width="48"
                        />
                    )}
                    {!userImageUrl && <Avatar />}
                </Headshot>
            </HeadshotWrapper>
            <Author>{author}</Author>
        </Link>
    )
})

const Post = styled(UnstyledPost)`
    position: relative;
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2.5rem 1rem 1.5rem;

    :hover,
    :focus,
    :active,
    :visited {
        text-decoration: none;
    }

    ::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        box-shadow: 0px 0px 20px rgb(0 0 0 / 8%);
        transition: opacity 300ms ease-in-out;
        top: 0;
        left: 0;
        opacity: 0;
    }

    :hover {
        ::after {
            opacity: 1;
        }
    }

    ${Content} {
        margin-top: 14px;
    }

    ${Author} {
        margin-top: 6px;
    }

    ${HeadshotWrapper} {
        display: none;
    }

    @media ${MqTablet} {
        flex-direction: row;
        align-items: center;
        padding: 1.5rem;

        ${HeadshotWrapper} {
            display: block;
        }

        ${Content} {
            margin-top: 0;
            margin-left: 1.5rem;
            margin-right: 3.75rem;
            flex: 1;
        }

        ${Author} {
            display: none;
        }
    }

    @media ${MqDesktop} {
        padding: 1.25rem 1.5rem;
    }
`

export default Post
