import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled, { css } from 'styled-components'

export const SectionTitle = styled.h2`
    color: ${({ $color }) => $color};
    font-size: 1.75rem;
    line-height: 2.5rem;
    padding: 0 1.25rem;
    margin: 0;
    text-align: ${({ $alwaysCentered = false }) => ($alwaysCentered ? 'center' : void 0)};

    a {
        color: inherit !important;
        text-decoration: none !important;
    }

    @media ${MqTablet} {
        font-size: 2.5rem;
        line-height: 3rem;
        text-align: center;
    }

    @media ${MqDesktop} {
        font-size: 3.75rem;
        line-height: 3.875rem;
    }

    ${({ $marginless = false }) =>
        !$marginless &&
        css`
            margin: 0 0 3.75rem;

            @media ${MqTablet} {
                margin-bottom: 5rem;
            }

            @media ${MqDesktop} {
                margin-bottom: 6rem;
            }
        `}
`
