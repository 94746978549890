import React from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop, Bold } from 'streamr-ui/css/consts'
import IconBox from '../Top/IconBox'
import Container from '~shared/Container'
import Topic from '~shared/Topic'
import urls from '~utils/urls'
import { OutlinedButton, ButtonSize, ButtonPaint, Link } from 'streamr-ui'

const UnstyledBuildWithStreamr = (props) => (
    <Container {...props}>
        <IconBox>
            <IconBox.Body icon="datafund">
                <Topic.Title>Get funded</Topic.Title>
                <Topic.Paragraph>
                    The Data Fund exists to incentivize the Streamr builder ecosystem. With grants
                    up to $50k, it can help to transform your real-time data idea into a built
                    project. Check out the application page or drop by our Discord for any
                    questions.
                </Topic.Paragraph>
                <OutlinedButton
                    tag={Link}
                    size={ButtonSize.Moose}
                    paint={ButtonPaint.PrimaryOutlined}
                    href={urls.grants}
                >
                    Apply now
                </OutlinedButton>
            </IconBox.Body>
        </IconBox>
        <IconBox>
            <IconBox.Body icon="node">
                <Topic.Title>Run a node</Topic.Title>
                <Topic.Paragraph>
                    Anyone can run a node on the Streamr Network and earn DATA tokens. If
                    you&apos;re running your own app on Streamr, or you just want to help secure the
                    network, dive in and check it out.
                </Topic.Paragraph>
                <OutlinedButton
                    tag="a"
                    size={ButtonSize.Moose}
                    paint={ButtonPaint.PrimaryOutlined}
                    href={urls.docs.runStreamrNode}
                >
                    Streamr docs
                </OutlinedButton>
            </IconBox.Body>
        </IconBox>
    </Container>
)

const BuildWithStreamr = styled(UnstyledBuildWithStreamr)`
    padding: 0 1rem;

    ${Topic.Title} {
        margin: 0;
        margin-bottom: 1rem;
        text-align: left;
        font-weight: ${Bold};
    }

    ${Topic.Paragraph} {
        margin-bottom: 3rem;
        line-height: 1.75rem;
        font-size: 1rem;
    }

    ${IconBox} + ${IconBox} {
        margin-top: 1.5rem;
    }

    @media ${MqTablet} {
        padding: 0 2rem;
        max-width: 912px;
        margin: 0 auto;

        ${IconBox} + ${IconBox} {
            margin-top: 3.5rem;
        }
    }

    @media ${MqDesktop} {
        max-width: 1160px;

        ${Topic.Paragraph} {
            font-size: 1.125rem;
            line-height: 1.875rem;
        }
    }
`

export default BuildWithStreamr
