import React from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop, Bold, Medium } from 'streamr-ui/css/consts'
import Container from '~shared/Container'
import Topic from '~shared/Topic'
import { LinkButton, ButtonSize, ButtonPaint } from 'streamr-ui'
import dataUnions from './assets/data-unions.png'
import dataUnions2x from './assets/data-unions@2x.png'
import hub from './assets/hub.png'
import hub2x from './assets/hub@2x.png'
import urls from '~utils/urls'

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;

    @media ${MqTablet} {
        grid-template-columns: repeat(2, 336px);
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
    }

    @media ${MqDesktop} {
        grid-template-columns: repeat(2, 560px);
    }
`

const Box = styled.div`
    background: white;
    border-radius: 16px;
    position: relative;
    padding: 37px 25px;
    height: 456px;
    display: flex;
    flex-direction: column;

    @media ${MqTablet} {
        padding: 37px 27px;
    }

    @media ${MqDesktop} {
        padding: 60px 40px 45px 40px;
        height: 628px;
    }
`

const Image = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    height: auto;

    &.data-unions {
        max-width: 161px;
        bottom: 80px;
        right: 33px;
    }

    &.hub {
        max-width: 148px;
        bottom: 69px;
        right: 35px;
    }

    @media ${MqTablet} {
    }

    @media ${MqDesktop} {
        &.data-unions {
            max-width: 284px;
            bottom: 107px;
            right: 55px;
        }

        &.hub {
            max-width: 248px;
            bottom: 99px;
            right: 65px;
        }
    }
`

const UnstyledLayers = (props) => (
    <Container {...props}>
        <Grid>
            <Box $appear>
                <Image
                    className="data-unions"
                    src={dataUnions}
                    srcSet={`${dataUnions2x} 2x`}
                    alt="Data unions"
                />
                <Topic.Title>Data Unions</Topic.Title>
                <Topic.Paragraph>
                    Framework to incentivize the creation of crowdsourced data.
                </Topic.Paragraph>
                <LinkButton
                    tag="a"
                    size={ButtonSize.Moose}
                    paint={ButtonPaint.PrimaryOutlined}
                    href={urls.docs.dataUnions}
                >
                    Learn more
                    <svg
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.7544 0.666016L15.8812 5.93961L10.7544 11.2132"
                            stroke="#0324FF"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M15.6494 5.93945H2.16016"
                            stroke="#0324FF"
                            strokeWidth="1.5"
                            strokeLinecap="square"
                        />
                    </svg>
                </LinkButton>
            </Box>
            <Box $appear>
                <Image className="hub" src={hub} srcSet={`${hub2x} 2x`} alt="The Hub" />
                <Topic.Title>The Hub</Topic.Title>
                <Topic.Paragraph>
                    Connect your wallet to create and manage your streams.
                </Topic.Paragraph>
                <LinkButton tag="a" href={urls.apps.hub}>
                    Visit app
                    <svg
                        width="29"
                        height="30"
                        viewBox="0 0 29 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.3281 10.0488C11.3281 9.6605 11.6324 9.3457 12.0078 9.3457H19.2578C19.6332 9.3457 19.9375 9.6605 19.9375 10.0488V17.5488C19.9375 17.9372 19.6332 18.252 19.2578 18.252C18.8824 18.252 18.5781 17.9372 18.5781 17.5488V10.752H12.0078C11.6324 10.752 11.3281 10.4372 11.3281 10.0488Z"
                            fill="#0324FF"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19.7384 9.55164C20.0039 9.82623 20.0039 10.2714 19.7384 10.546L9.76967 20.8585C9.50424 21.1331 9.07389 21.1331 8.80845 20.8585C8.54302 20.5839 8.54302 20.1387 8.80845 19.8641L18.7772 9.55164C19.0426 9.27706 19.473 9.27706 19.7384 9.55164Z"
                            fill="#0324FF"
                        />
                    </svg>
                </LinkButton>
            </Box>
        </Grid>
    </Container>
)

const Layers = styled(UnstyledLayers)`
    ${Topic.Title} {
        margin: 0;
        margin-bottom: 8px;
        text-align: left;
        font-weight: ${Bold};
        font-size: 20px;
    }

    ${Topic.Paragraph} {
        line-height: 1.75rem;
        font-size: 1rem;
        max-width: 260px;
    }

    ${LinkButton} {
        margin-top: auto;
        font-weight: ${Medium};
        text-decoration: none;
        color: #0324ff;

        & > div {
            display: flex;
            align-items: center;
        }

        svg {
            margin-left: 4px;
        }
    }

    @media ${MqTablet} {
        max-width: 44rem;
    }

    @media ${MqDesktop} {
        max-width: 1160px;

        ${Topic.Title} {
            font-size: 28px;
            margin-bottom: 16px;
        }

        ${Topic.Paragraph} {
            font-size: 1.125rem;
            line-height: 1.875rem;
            max-width: 285px;
        }
    }
`

export default Layers
