import React from 'react'
import styled from 'styled-components'
import Post from './Post'

const UnstyledBlogPosts = ({ posts, ...props }) => (
    <div {...props}>
        {(posts || []).map(({ id, title, summary, author, url, userImageURL }) => (
            <div key={id}>
                <Post $appear url={url} title={title} author={author} userImageUrl={userImageURL}>
                    {summary}
                </Post>
            </div>
        ))}
    </div>
)

const BlogPosts = styled(UnstyledBlogPosts)`
    > * + * {
        margin-top: 1.5rem;
    }
`

export default BlogPosts
