import React from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import dataFund from './assets/data-fund.png'
import dataFund2x from './assets/data-fund@2x.png'
import network from './assets/network.png'
import network2x from './assets/network@2x.png'
import node from './assets/node.png'
import node2x from './assets/node@2x.png'
import techstack from './assets/tech-stack.png'
import techstack2x from './assets/tech-stack@2x.png'
import stream from './assets/stream.png'
import stream2x from './assets/stream@2x.png'

const assets = {
    datafund: [dataFund, dataFund2x],
    network: [network, network2x],
    node: [node, node2x],
    techstack: [techstack, techstack2x],
    stream: [stream, stream2x],
}

const UnstyledIcon = ({ name, ...props }) => {
    if (!assets[name]) {
        return null
    }

    const [icon, icon2x] = assets[name]

    return (
        <div {...props}>
            <img src={icon} srcSet={`${icon2x} 2x`} alt="" />
        </div>
    )
}

const Icon = styled(UnstyledIcon)`
    img {
        max-width: 115px;
    }

    @media ${MqDesktop} {
        img {
            max-width: 150px;
        }
    }
`

const Content = styled.div`
    @media ${MqTablet} {
        max-width: 384px;
    }

    @media ${MqDesktop} {
        max-width: 550px;
    }
`

const UnstyledBody = ({ icon, children, ...props }) => (
    <div {...props}>
        <Content>{children}</Content>
        <Icon name={icon} />
    </div>
)

const Body = styled(UnstyledBody)`
    display: flex;
    flex-direction: column-reverse;

    ${Icon} {
        margin-bottom: 2.5rem;
    }

    @media ${MqTablet} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        ${Icon} {
            margin-bottom: 0;
        }
    }
`

const UnstyledIconBox = ({ children, ...props }) => (
    <div {...props} $appear>
        {children}
    </div>
)

const IconBox = styled(UnstyledIconBox)`
    background-color: #ffffff;
    border-radius: 8px;
    padding: 3.5rem 1.5rem 3.5rem 1.5rem;

    @media ${MqTablet} {
        padding: 4rem 5.5rem 4rem 2rem;
    }

    @media (min-width: 1024px) {
        margin: 0 auto;
        padding: 6rem 8.5rem 6rem 5rem;
    }

    @media ${MqDesktop} {
        padding: 6rem 8.5rem 6rem 7.5rem;
    }
`

export default Object.assign(IconBox, {
    Body,
    Icon,
})
