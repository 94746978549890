import React from 'react'
import ForDevelopers from '../components/ForDevelopers'
import page from '~utils/page'
import { NETWORK } from '~shared/Nav'
import Head from '~shared/Head'

// eslint-disable-next-line import/no-unused-modules
export default page(ForDevelopers, {
    highlight: NETWORK,
    streamrClient: true,
    headComponent: (
        <Head
            mediaDescription="For developers"
            // eslint-disable-next-line max-len
            description="A toolkit that enables you to publish and subscribe to json based real-time messages powered by the decentralized Streamr network. Think notifications, wallet to wallet communication and IoT connectivity."
        />
    ),
})
