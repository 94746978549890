import React from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop, Bold } from 'streamr-ui/css/consts'
import Swipeable from '~shared/Swipeable'
import FeatureCard from '~shared/FeatureCard'
import Topic from '~shared/Topic'
import Stops from '~shared/Stops'
import SliderNav from '~shared/SliderNav'
import Spacer from '~shared/Spacer'
import Display from '~shared/Display'
import { SectionTitle } from '../shared/PersonaPage'

const Apps = styled.div`
    display: grid;
    margin: 40px 2rem 0;
    grid-template-columns: repeat(4, 280px);
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    transition: 300ms transform ease-in-out;
    width: fit-content;

    ${FeatureCard} {
        position: relative;
        display: flex;

        ::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
            transition: opacity 300ms ease-in-out;
            top: 0;
            left: 0;
            opacity: 0;
            pointer-events: none;
        }

        :hover {
            ::after {
                opacity: 1;
            }
        }
    }

    ${FeatureCard} ul {
        padding: 0 14px;
        margin: 0;
    }

    ${FeatureCard} ul li {
        margin-bottom: 8px;
    }

    @media ${MqTablet} {
        transition: none;
        transform: none !important;
        margin: 80px auto 0;
        grid-template-columns: repeat(2, 336px);
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
    }

    @media ${MqDesktop} {
        margin: 88px auto 0;
        width: fit-content;
        grid-template-columns: repeat(4, 280px);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }
`

const UnstyledPowerYourStack = (props) => (
    <div {...props}>
        <SectionTitle $appear>
            <a href="#power-your-stack">
                Power your Web3 stack
                <br />
                with real-time communication
            </a>
        </SectionTitle>
        <Topic.Body>
            <Stops current={0} size={4}>
                {({ size, current, setStop }) => (
                    <Swipeable
                        onSwipedLeft={() => setStop(Math.min(size - 1, current + 1))}
                        onSwipedRight={() => setStop(Math.max(0, current - 1))}
                    >
                        <Apps
                            style={{
                                transform: `translateX(calc(-${current * 280}px - ${
                                    current * 1.5
                                }rem))`,
                            }}
                        >
                            <FeatureCard $appear icon="networkexplorer" title="Fast & scalable">
                                <ul>
                                    <li>
                                        Sub-second, ordered message delivery over a peer-to-peer
                                        network.
                                    </li>
                                    <li>
                                        Censorship resistant publish/subscribe messaging at massive
                                        scale.
                                    </li>
                                </ul>
                            </FeatureCard>
                            <FeatureCard $appear icon="lock" title="Secure">
                                <ul>
                                    <li>
                                        Every message is cryptographically signed and end-to-end
                                        encrypted.
                                    </li>
                                    <li>
                                        Leverage composability and control access to your data via
                                        smart contracts.
                                    </li>
                                </ul>
                            </FeatureCard>
                            <FeatureCard $appear icon="core" title="Serverless">
                                <ul>
                                    <li>Runs anywhere JavaScript runs, including the browser.</li>
                                    <li>Free to use peer-to-peer architecture.</li>
                                </ul>
                            </FeatureCard>
                            <FeatureCard $appear icon="sponsor" title="Monetize">
                                <ul>
                                    <li>
                                        Sell access to your data streams with composable smart
                                        contracts.
                                    </li>
                                    <li>
                                        Leverage the Data Union framework to pay your users for
                                        providing data.
                                    </li>
                                </ul>
                            </FeatureCard>
                        </Apps>
                        <Display as={Spacer} head="4rem" mobile="block" tablet="none">
                            <SliderNav
                                size={4}
                                current={current}
                                onChange={setStop}
                                theme={SliderNav.DarkBlueTheme}
                            />
                        </Display>
                    </Swipeable>
                )}
            </Stops>
        </Topic.Body>
    </div>
)

const PowerYourStack = styled(UnstyledPowerYourStack)`
    overflow: hidden;
    width: 100%;

    ${Topic.Title} {
        font-size: 2rem;
        font-weight: ${Bold};
        line-height: 36px;
    }

    ${Topic.Paragraph} {
        display: none;
        margin: 0;
    }

    @media ${MqTablet} {
        ${Topic.Title} {
            margin: 0;
            font-size: 2.5rem;
            line-height: 48px;
        }

        ${Topic.Paragraph} {
            margin-top: 1.5rem;
            display: block;
            text-align: center;
            font-size: 1rem;
        }
    }

    @media ${MqDesktop} {
        ${Topic.Title} {
            font-size: 3.75rem;
            line-height: 66px;
        }

        ${Topic.Paragraph} {
            margin-top: 2.5rem;
            font-size: 1.125rem;
        }
    }
`

export default PowerYourStack
