import React from 'react'
import styled from 'styled-components'
import Tiles from './Tiles'
import Spacer from '~shared/Spacer'
import { Bold, MqDesktop, MqTablet } from 'streamr-ui/css/consts'

export default function PersonaPageHero({
    title = 'Untitled',
    desc = 'Lorem ipsum dolor sit emat.',
    persona,
}) {
    return (
        <Root>
            <Content>
                <h1>{title}</h1>
                {typeof desc === 'string' ? <p>{desc}</p> : desc}
            </Content>
            <Spacer pad head="12rem,15.5rem,23rem" tail="15rem,20.75rem,19.25rem">
                <Tiles persona={persona} />
            </Spacer>
        </Root>
    )
}

const Root = styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;

    h1 {
        font-size: 2rem;
        font-weight: ${Bold};
        line-height: 2.25rem;
        margin: 7.5rem auto 0;
        max-width: 19.5rem;
        text-align: center;
    }

    p {
        line-height: 1.625rem;
        margin: 1.5rem auto 0;
        max-width: 100%;
        text-align: center;
        width: 22rem;
    }

    @media ${MqTablet} {
        h1 {
            font-size: 3.75rem;
            line-height: 5.125rem;
            max-width: 43rem;
            margin-top: 10.5rem;
        }

        p {
            font-size: 1.25rem;
            line-height: 2.125rem;
            margin-top: 2.5rem;
            width: 35.75rem;
        }
    }

    @media ${MqDesktop} {
        h1 {
            font-size: 5.5rem;
            line-height: 6.125rem;
            max-width: 68rem;
            margin-top: 12.75rem;
        }

        p {
            font-size: 1.375rem;
            line-height: 2.25rem;
            margin-top: 3.75rem;
            width: 47.75rem;
        }
    }
`

const Content = styled.div`
    pointer-events: none;
    position: relative;
    z-index: 1;
`
