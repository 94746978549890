import React from 'react'
import { Medium, MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

export default function PersonaPageBadge({ iconImage, children, href, ...props }) {
    const Tag = href ? 'a' : GatsbyLink

    return (
        <Root
            {...props}
            as={Tag}
            rel={href ? 'noopener noreferrer' : void 0}
            target={href ? '_blank' : void 0}
            href={href}
        >
            <ImageWrap>{iconImage}</ImageWrap>
            <Content>{children}</Content>
            <SvgWrap>
                <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.75006 1C1.75006 0.585786 2.08585 0.25 2.50006 0.25H12.5001C12.9143 0.25 13.2501 0.585786 13.2501 1V11C13.2501 11.4142 12.9143 11.75 12.5001 11.75C12.0858 11.75 11.7501 11.4142 11.7501 11V2.64421L2.09668 12.5125C1.80703 12.8086 1.33219 12.8138 1.03609 12.5242C0.739987 12.2345 0.73476 11.7597 1.02441 11.4636L10.5265 1.75H2.50006C2.08585 1.75 1.75006 1.41421 1.75006 1Z"
                        fill="#0324FF"
                    />
                </svg>
            </SvgWrap>
        </Root>
    )
}

const Root = styled.div`
    align-items: center;
    background: white;
    border-radius: 0.5rem;
    border-radius: 8px;
    box-shadow: 0px 6.30704px 50px -6.30704px rgba(0, 0, 0, 0.1);
    color: #0324ff !important;
    cursor: pointer;
    display: flex;
    font-weight: ${Medium};
    line-height: 1.375rem;
    padding: 0 1rem 0 1.5rem;
    text-decoration: none !important;
    transition: box-shadow 200ms ease-in-out;
    user-select: none;

    :hover {
        box-shadow: 0px 6.30704px 50px -6.30704px rgba(0, 0, 0, 0.3);
    }
`

const ImageWrap = styled.div`
    aligh-items: center;
    display: flex;
    flex-shrink: 0;
    height: 2.5rem;
    justify-content: center;
    margin-right: 1.5rem;
    width: 2.5rem;

    :empty {
        background: rgba(255, 0, 0, 0.1);
    }
`

const Content = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    min-height: 5rem;
    padding: 0.75rem 0;
`

const SvgWrap = styled.div`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 2rem;
    justify-content: center;
    width: 2rem;

    svg {
        display: block;
    }
`

const BadgeList = styled.ul`
    list-style: none;
    display: grid;
    gap: 1.25rem;
    padding: 0;
    margin: 0;

    @media ${MqTablet} {
        gap: 1.75rem;
    }

    ${({ $singleColumn = false }) =>
        !$singleColumn &&
        css`
            @media ${MqDesktop} {
                grid-template-columns: repeat(
                    ${({ children }) => React.Children.count(children)},
                    1fr
                );
            }
        `}
`

export function PersonaPageBadgeList({ children, singleColumn }) {
    return (
        <BadgeList $singleColumn={singleColumn}>
            {React.Children.map(children, (child) => (
                <li>{child}</li>
            ))}
        </BadgeList>
    )
}

export const PersonaPageBadgeColumn = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 1.25rem;
    width: 27.5rem;
    max-width: 100%;

    @media ${MqTablet} {
        padding: 0 2rem;
    }

    @media ${MqDesktop} {
        max-width: 56.75rem;
        width: ${({ $desktopWidth = 'auto' }) => $desktopWidth};
    }
`
