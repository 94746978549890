import React from 'react'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import usePosts from '~hooks/usePosts'
import BlogPosts from '~shared/BlogPosts'
import ButtonWrapper from '~shared/ButtonWrapper'
import Container from '~shared/Container'
import Layout from '~shared/Layout'
import PersonaPageBadge, {
    PersonaPageBadgeColumn,
    PersonaPageBadgeList,
} from '~shared/PersonaPage/PersonaPageBadge'
import PersonaPageHero from '~shared/PersonaPage/PersonaPageHero'
import PersonaPageSection from '~shared/PersonaPage/PersonaPageSection'
import discord from '~shared/PersonaPage/assets/discord.svg'
import docs from '~shared/PersonaPage/assets/docs.png'
import docs2x from '~shared/PersonaPage/assets/docs@2x.png'
import Rescroll from '~shared/Rescroll'
import Spacer from '~shared/Spacer'
import Topic from '~shared/Topic'
import useTranslation from '~utils/useTranslation'
import urls from '~utils/urls'
import { SectionTitle } from '../shared/PersonaPage'
import BuildWithStreamr from './BuildWithStreamr'
// import DeveloperGuides from './DeveloperGuides'
import Layers from './Layers'
import NetworkExplorer from './NetworkExplorer'
import PowerYourStack from './PowerYourStack'
import TheHub from './TheHub'

const BlogTopic = styled(Topic)`
    ${Topic.Title} {
        text-align: center;
    }
    ${Container} {
        padding: 0 1rem;
    }
    @media ${MqTablet} {
        ${Container} {
            padding: 0 2rem;
        }
    }
`

const UnstyledForDevelopers = (props) => {
    const { t } = useTranslation()

    const posts = usePosts({
        limit: 4,
    })

    return (
        <Layout {...props}>
            <PersonaPageSection gradient="180deg, #E6E6F4 0%, #E5E7FA 50%, rgba(232, 233, 247, 0) 75%">
                <PersonaPageHero
                    persona="developer"
                    title="The data stream toolkit for Web3 builders"
                    desc={
                        <p>
                            A toolkit that enables you to publish and subscribe to real-time
                            messages powered by the decentralized Streamr Network. Think
                            notifications, wallet to&nbsp;wallet communication and IoT connectivity.
                        </p>
                    }
                />
                <Spacer pad head=",,2rem">
                    <PersonaPageBadgeColumn>
                        <PersonaPageBadgeList>
                            <PersonaPageBadge
                                href="https://docs.streamr.network/quickstart/nodejs"
                                iconImage={<img src={docs} srcSet={`${docs2x} 2x`} alt="Docs" />}
                            >
                                Get started
                            </PersonaPageBadge>
                            <PersonaPageBadge
                                href={urls.community.discord}
                                iconImage={<img src={discord} alt="Discord" />}
                            >
                                Get technical support from Streamr&nbsp;community members
                            </PersonaPageBadge>
                        </PersonaPageBadgeList>
                    </PersonaPageBadgeColumn>
                </Spacer>
                <div id="power-your-stack" />
                <Spacer head="9.25rem,17.5rem,16.75rem" tail="8.75rem,9rem,12.5rem">
                    <PowerYourStack />
                </Spacer>
            </PersonaPageSection>
            {/* Uncomment when links are all hooked up. */}
            {/* <div id="developer-guides" /> */}
            {/* <DeveloperGuides /> */}
            <TheHub />
            <div id="application-layer" />
            <Spacer head="7.9rem,9rem,12rem">
                <SectionTitle $appear>
                    <a href="#application-layer">Application layer</a>
                </SectionTitle>
                <Layers />
            </Spacer>
            <NetworkExplorer />
            <BuildWithStreamr />
            <div id="news" />
            {posts.length > 0 && (
                <Rescroll>
                    <BlogTopic
                        pad
                        backgroundColor="#FAFAFA"
                        color="#0C009A"
                        head="6rem,11.5rem,10.5rem"
                        tail="0"
                    >
                        <div>
                            <SectionTitle $appear>
                                <a href="#news">{t('top.blogposts.title')}</a>
                            </SectionTitle>
                            <Topic.Body>
                                <Container>
                                    <BlogPosts posts={posts} />
                                </Container>
                            </Topic.Body>
                        </div>
                    </BlogTopic>
                </Rescroll>
            )}
        </Layout>
    )
}

const ForDevelopers = styled(UnstyledForDevelopers)`
    color: #0c009a;
    padding-bottom: 9rem;

    ${ButtonWrapper} {
        margin: 60px auto 0 auto;
        display: flex;
        justify-content: center;
    }

    @media ${MqTablet} {
        padding-bottom: 8.87rem;

        ${ButtonWrapper} {
            margin: 120px auto 0 auto;
        }
    }

    @media ${MqDesktop} {
        padding-bottom: 10rem;
    }
`

export default ForDevelopers
