import React from 'react'
import styled from 'styled-components'
import Topic from '~shared/Topic'
import Container from '~shared/Container'
import { Bold, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import { PrimaryButton, ButtonSize, ButtonPaint } from 'streamr-ui'
import thehublg from './assets/thehub-lg.png'
import thehublg2x from './assets/thehub-lg@2x.png'
import thehubmd from './assets/thehub-md.png'
import thehubmd2x from './assets/thehub-md@2x.png'
import thehubsm from './assets/thehub-sm.png'
import thehubsm2x from './assets/thehub-sm@2x.png'
import urls from '~utils/urls'

const ImageLg = styled.img`
    display: none;

    @media ${MqDesktop} {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 615px;
    }
`

const ImageMd = styled.img`
    display: none;

    @media ${MqTablet} {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 342px;
    }

    @media ${MqDesktop} {
        display: none;
    }
`

const ImageSm = styled.img`
    width: 100%;
    max-width: 336px;
    margin: 0 auto;
    margin-top: 64px;

    @media ${MqTablet} {
        display: none;
    }
`

const UnstyledTheHub = (props) => (
    <>
        <div id="hub" />
        <Topic {...props} head="0,9rem,12.25rem">
            <Container>
                <Topic.Title $appear>
                    <a href="#hub">The Hub</a>
                </Topic.Title>
                <Topic.Paragraph $appear>
                    Create and connect with live streaming&nbsp;data
                </Topic.Paragraph>
                <PrimaryButton
                    size={ButtonSize.Moose}
                    paint={ButtonPaint.Primary}
                    blank
                    tag="a"
                    href={urls.apps.hub}
                >
                    Go to The Hub
                </PrimaryButton>
                <ImageSm src={thehubsm} srcSet={`${thehubsm2x} 2x`} alt="The Hub" />
                <ImageMd src={thehubmd} srcSet={`${thehubmd2x} 2x`} alt="The Hub" />
                <ImageLg src={thehublg} srcSet={`${thehublg2x} 2x`} alt="The Hub" />
            </Container>
        </Topic>
    </>
)

const TheHub = styled(UnstyledTheHub)`
    position: relative;
    overflow: hidden;
    background-color: #fafafa;
    color: white;

    ${Container} {
        position: relative;
        background: #121622;
        height: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    ${Topic.Title} {
        font-size: 1.75rem;
        font-weight: ${Bold};
        margin-bottom: 4px;
        margin-left: 0;
        margin-right: 0;
        text-align: left;
    }

    ${Topic.Paragraph} {
        font-size: 18px;
        text-align: left;
    }

    ${PrimaryButton} {
        border-radius: 8px;
        margin-top: 30px;
        max-width: 152px;
    }

    @media ${MqTablet} {
        ${Container} {
            height: 424px;
            max-width: 40rem;
            border-radius: 16px;
        }

        ${Topic.Title} {
            font-size: 2.5rem;
            margin-bottom: 16px;
        }

        ${Topic.Paragraph} {
            max-width: 275px;
        }

        ${PrimaryButton} {
            margin-top: 40px;
        }
    }

    @media ${MqDesktop} {
        ${Container} {
            box-sizing: border-box;
            max-width: 1160px;
            height: 560px;
            padding: 0 60px;
        }

        ${Topic.Title} {
            font-size: 3.75rem;
        }

        ${Topic.Paragraph} {
            max-width: 336px;
        }

        ${PrimaryButton} {
            margin-top: 46px;
        }
    }
`

export default TheHub
