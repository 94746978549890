import GhostContentAPI from '@tryghost/content-api'
import { useGhostBlogPosts } from 'streamr-ui/hooks'

const api = new GhostContentAPI({
    url: 'https://streamr.ghost.io',
    key: 'e1257cb1e62674f4be947563d4',
    version: 'v3',
})

export default function usePosts(params) {
    return useGhostBlogPosts(api, params)
}
