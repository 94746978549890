import React, { useMemo } from 'react'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled, { css } from 'styled-components'
import developer from './assets/developer.png'
import developer2x from './assets/developer@2x.png'
import developerMobile from './assets/developer_mobile.png'
import developerMobile2x from './assets/developer_mobile@2x.png'
import developerTablet from './assets/developer_tablet.png'
import developerTablet2x from './assets/developer_tablet@2x.png'
import hodler from './assets/hodler.png'
import hodler2x from './assets/hodler@2x.png'
import hodlerMobile from './assets/hodler_mobile.png'
import hodlerMobile2x from './assets/hodler_mobile@2x.png'
import hodlerTablet from './assets/hodler_tablet.png'
import hodlerTablet2x from './assets/hodler_tablet@2x.png'
import nodeRunner from './assets/node_runner.png'
import nodeRunner2x from './assets/node_runner@2x.png'
import nodeRunnerMobile from './assets/node_runner_mobile.png'
import nodeRunnerMobile2x from './assets/node_runner_mobile@2x.png'
import nodeRunnerTablet from './assets/node_runner_tablet.png'
import nodeRunnerTablet2x from './assets/node_runner_tablet@2x.png'
import tile from './assets/tile_desktop.png'
import tile2x from './assets/tile_desktop@2x.png'
import tileMobile from './assets/tile_mobile.png'
import tileMobile2x from './assets/tile_mobile@2x.png'
import tileTablet from './assets/tile_tablet.png'
import tileTablet2x from './assets/tile_tablet@2x.png'

const items = []

for (let y = 1; y > -5; y--) {
    for (let x = -5; x <= 5; x++) {
        items.push([x, y])
    }
}

function shouldRenderTile(x, y) {
    return (x === 0 && y === 0) || y >= 0 || Math.random() < ((5 - Math.abs(x)) / 5) * 0.75
}

export default function Tiles({ persona }) {
    const tiles = useMemo(
        () =>
            items.map(([x, y]) => (
                <Tile key={`${x}/${y}`} $x={x} $y={y} $invisible={!shouldRenderTile(x, y)} />
            )),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [persona],
    )

    return (
        <Root>
            {tiles}
            <Wrap>
                {persona !== 'developer' && persona !== 'nodeRunner' && (
                    <>
                        <DesktopImg src={hodler} srcSet={`${hodler2x} 2x`} alt="Hodler" />
                        <TabletImg
                            src={hodlerTablet}
                            srcSet={`${hodlerTablet2x} 2x`}
                            alt="Hodler"
                        />
                        <MobileImg
                            src={hodlerMobile}
                            srcSet={`${hodlerMobile2x} 2x`}
                            alt="Hodler"
                        />
                    </>
                )}
                {persona === 'nodeRunner' && (
                    <>
                        <DesktopImg
                            src={nodeRunner}
                            srcSet={`${nodeRunner2x} 2x`}
                            alt="Node runner"
                        />
                        <TabletImg
                            src={nodeRunnerTablet}
                            srcSet={`${nodeRunnerTablet2x} 2x`}
                            alt="Node runner"
                        />
                        <MobileImg
                            src={nodeRunnerMobile}
                            srcSet={`${nodeRunnerMobile2x} 2x`}
                            alt="Node runner"
                        />
                    </>
                )}
                {persona === 'developer' && (
                    <>
                        <DesktopImg src={developer} srcSet={`${developer2x} 2x`} alt="Developer" />
                        <TabletImg
                            src={developerTablet}
                            srcSet={`${developerTablet2x} 2x`}
                            alt="Developer"
                        />
                        <MobileImg
                            src={developerMobile}
                            srcSet={`${developerMobile2x} 2x`}
                            alt="Developer"
                        />
                    </>
                )}
            </Wrap>
        </Root>
    )
}

const Root = styled.div`
    height: 0;
    margin: 0 auto;
    overflow: visible;
    width: 0;

    img {
        user-select: none;
    }
`

function transform(pX, pY, hDistanceInRem, vDistanceInRem) {
    const dx = [
        '-50%',
        `${pX * 100}%`,
        `${pX * hDistanceInRem}rem`,
        `${Math.abs(pY % 2) * -50}%`,
        `${Math.abs(pY % 2) * -hDistanceInRem * 0.5}rem`,
    ]

    const dy = ['-50%', `${pY * 50}%`, `${pY * vDistanceInRem}rem`]

    return [...dx.map((v) => `translateX(${v})`), ...dy.map((v) => `translateY(${v})`)].join(' ')
}

const DesktopImg = styled.img`
    display: none;

    @media ${MqDesktop} {
        display: block;
    }
`

const TabletImg = styled.img`
    display: none;

    @media ${MqTablet} {
        display: block;
    }

    @media ${MqDesktop} {
        display: none;
    }
`

const MobileImg = styled.img`
    display: block;

    @media ${MqTablet} {
        display: none;
    }
`

function UnstyledTile(props) {
    return (
        <div {...props}>
            <DesktopImg src={tile} srcSet={`${tile2x} 2x`} alt="Tile" />
            <TabletImg src={tileTablet} srcSet={`${tileTablet2x} 2x`} alt="Tile" />
            <MobileImg src={tileMobile} srcSet={`${tileMobile2x} 2x`} alt="Tile" />
        </div>
    )
}

const Tile = styled(UnstyledTile)`
    position: absolute;
    transform: ${({ $y = 0, $x = 0 }) => transform($x, $y, 7, 2.25)};
    opacity: ${({ $y, $x }) => ($x === 0 && $y === 0 ? 1 : 0.1 + Math.random() * 0.3)};
    display: ${({ $invisible = false }) => ($invisible ? 'none' : 'block')};

    img {
        height: auto;
    }

    ${({ $x, $y }) =>
        ($x !== 0 || $y !== 0) &&
        css`
            transition: 2s opacity ease-in-out;

            :hover {
                opacity: 0.7;
            }
        `}

    @media ${MqTablet} {
        transform: ${({ $y = 0, $x = 0 }) => transform($x, $y, 9.5, 2.75)};
        width: 20rem;
    }

    @media ${MqDesktop} {
        transform: ${({ $y = 0, $x = 0 }) => transform($x, $y, 12, 3.5)};
        width: 26.25rem;
    }
`

const Wrap = styled.div`
    position: relative;

    img {
        transform: translate(-50%, -100%) translateY(26%);
    }

    @media ${MqTablet} {
        img {
            transform: translate(-50%, -100%) translateY(27%);
        }
    }

    @media ${MqDesktop} {
        img {
            transform: translate(-50%, -100%) translateY(28%);
        }
    }
`
