import React from 'react'
import styled from 'styled-components'
import Topic from '~shared/Topic'
import Container from '~shared/Container'
import { Bold, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import { PrimaryButton, ButtonSize, ButtonPaint } from 'streamr-ui'
import background from './assets/network-explorer.png'
import background2x from './assets/network-explorer@2x.png'
import urls from '~utils/urls'

const Image = styled.img`
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media ${MqDesktop} {
    }
`

const Content = styled.div`
    margin-left: auto;

    @media ${MqTablet} {
    }

    @media ${MqDesktop} {
        margin-right: 160px;
    }
`

const UnstyledNetworkExplorer = (props) => (
    <Topic {...props} head="1.25rem,2rem,2rem" tail="9rem,8.5rem,11.75rem">
        <Container>
            <Image src={background} srcSet={`${background2x} 2x`} alt="Network explorer" />
            <Content>
                <Topic.Title $appear>Network explorer</Topic.Title>
                <Topic.Paragraph $appear>
                    Explore live metrics for nodes and streams on the Network.
                </Topic.Paragraph>
                <PrimaryButton
                    size={ButtonSize.Moose}
                    tag="a"
                    href={urls.apps.networkExplorer}
                    paint={ButtonPaint.Primary}
                    blank
                >
                    Launch the app
                </PrimaryButton>
            </Content>
        </Container>
    </Topic>
)

const NetworkExplorer = styled(UnstyledNetworkExplorer)`
    position: relative;
    overflow: hidden;
    background-color: #fafafa;
    color: white;
    padding-left: 20px;
    padding-right: 20px;

    ${Container} {
        position: relative;
        background: #121622;
        height: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 16px;
        height: 410px;
    }

    ${Topic.Title} {
        font-size: 1.75rem;
        font-weight: ${Bold};
        margin-bottom: 4px;
        margin-left: 0;
        margin-right: 0;
        text-align: left;
    }

    ${Topic.Paragraph} {
        font-size: 16px;
        text-align: left;
    }

    ${PrimaryButton} {
        border-radius: 8px;
        margin-top: 30px;
        max-width: 152px;
    }

    @media ${MqTablet} {
        padding-left: 0;
        padding-right: 0;

        ${Container} {
            height: 424px;
            /* padding-left: 37%; */
        }

        ${Topic.Title} {
            font-size: 1.25rem;
            margin-bottom: 16px;
        }

        ${Topic.Paragraph} {
            max-width: 275px;
            font-size: 16px;
        }

        ${PrimaryButton} {
            margin-top: 30px;
        }
    }

    @media ${MqDesktop} {
        ${Container} {
            height: 560px;
            /* padding-left: 37%; */
        }

        ${Topic.Title} {
            font-size: 2.25rem;
        }

        ${Topic.Paragraph} {
            font-size: 18px;
            max-width: 286px;
        }

        ${PrimaryButton} {
            margin-top: 30px;
        }
    }
`

export default NetworkExplorer
