import React from 'react'
import { MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'

export default function PersonaPageSection({ gradient, mobileGradient = gradient, children }) {
    return (
        <Root $gradient={gradient} $mobileGradient={mobileGradient}>
            {children}
        </Root>
    )
}

const Root = styled.div`
    background: linear-gradient(${({ $mobileGradient }) => $mobileGradient});
    margin-top: -5.5rem;

    @media ${MqTablet} {
        background: linear-gradient(${({ $gradient }) => $gradient});
    }
`
