import React, { useLayoutEffect, useRef } from 'react'

export default function Rescroll({ children, ...props }) {
    const rootRef = useRef(null)

    useLayoutEffect(() => {
        if (typeof window === 'undefined') {
            return
        }

        const { current: root } = rootRef
        const height = root ? root.scrollHeight : 0
        const offsetTop = root ? root.offsetTop : 0
        const { scrollY } = window

        if (scrollY > offsetTop) {
            // We update scrollY to avoid scroll position jumps when the blog posts appear. Note
            // that this only happens if the current scroll position is below the blog section.
            window.scroll(0, scrollY + height)
        }
    }, [children])

    return (
        <div {...props} ref={rootRef}>
            {children}
        </div>
    )
}
