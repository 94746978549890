import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { Medium, MqDesktop } from 'streamr-ui/css/consts'
import core from './assets/core.png'
import core2x from './assets/core@2x.png'
import dataunions from './assets/dataunions.png'
import dataunions2x from './assets/dataunions@2x.png'
import networkExplorer from './assets/network-explorer.png'
import networkExplorer2x from './assets/network-explorer@2x.png'
import lock from './assets/lock.png'
import lock2x from './assets/lock@2x.png'
import sponsor from './assets/sponsor.png'
import sponsor2x from './assets/sponsor@2x.png'

const assets = {
    dataunions: [dataunions, dataunions2x],
    core: [core, core2x],
    networkexplorer: [networkExplorer, networkExplorer2x],
    lock: [lock, lock2x],
    sponsor: [sponsor, sponsor2x],
}

const UnstyledIcon = ({ name, ...props }) => {
    if (!assets[name]) {
        return null
    }

    const [icon, icon2x] = assets[name]

    return (
        <div {...props}>
            <img src={icon} srcSet={icon2x} alt="" />
        </div>
    )
}

const Icon = styled(UnstyledIcon)`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #f5f5f5;
    height: 208px;
    position: relative;

    img {
        width: 130px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media ${MqDesktop} {
        img {
            width: 110px;
        }
    }
`

const Content = styled.div`
    background-color: #ffffff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 1.25rem 2.25rem 2rem 1.875rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #0c009a;

    p {
        margin: 0;
    }

    p + p {
        margin-top: 1.25rem;
    }
`

const Title = styled.h3`
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: ${Medium};
    margin: 0;
    margin-bottom: 1.25rem;
`

const UnstyledFeatureCard = forwardRef(function UnstyledFeatureCard(
    { icon, title, children, to, ...props },
    ref,
) {
    return (
        <button
            {...props}
            type="button"
            onClick={to != null ? () => navigate(to) : () => {}}
            ref={ref}
        >
            <Icon name={icon} />
            <Content>
                <Title>{title}</Title>
                {children}
            </Content>
        </button>
    )
})

const FeatureCard = styled(UnstyledFeatureCard)`
    display: flex;
    flex-direction: column;
    display: block;
    text-align: left;
    appearance: none;
    background: transparent;
    margin: 0;
    padding: 0;
    border: 0;
    cursor: ${(props) => (props.to != null ? 'pointer' : 'auto')};
    align-items: stretch;

    ${Content} {
        flex: 1;
    }
`

export default FeatureCard
